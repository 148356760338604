/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SignupImport } from './routes/signup'
import { Route as LoginImport } from './routes/login'

// Create Virtual Routes

const ResetPasswordLazyImport = createFileRoute('/reset-password')()
const ProfileLazyImport = createFileRoute('/profile')()
const ForgotPasswordLazyImport = createFileRoute('/forgot-password')()
const AccountsLazyImport = createFileRoute('/accounts')()
const IndexLazyImport = createFileRoute('/')()
const CustomersIndexLazyImport = createFileRoute('/customers/')()
const CustomersCustomerIdLazyImport = createFileRoute(
  '/customers/$customerId',
)()

// Create/Update Routes

const ResetPasswordLazyRoute = ResetPasswordLazyImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/reset-password.lazy').then((d) => d.Route),
)

const ProfileLazyRoute = ProfileLazyImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/profile.lazy').then((d) => d.Route))

const ForgotPasswordLazyRoute = ForgotPasswordLazyImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/forgot-password.lazy').then((d) => d.Route),
)

const AccountsLazyRoute = AccountsLazyImport.update({
  id: '/accounts',
  path: '/accounts',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/accounts.lazy').then((d) => d.Route))

const SignupRoute = SignupImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const CustomersIndexLazyRoute = CustomersIndexLazyImport.update({
  id: '/customers/',
  path: '/customers/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/customers/index.lazy').then((d) => d.Route),
)

const CustomersCustomerIdLazyRoute = CustomersCustomerIdLazyImport.update({
  id: '/customers/$customerId',
  path: '/customers/$customerId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/customers/$customerId.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/signup': {
      id: '/signup'
      path: '/signup'
      fullPath: '/signup'
      preLoaderRoute: typeof SignupImport
      parentRoute: typeof rootRoute
    }
    '/accounts': {
      id: '/accounts'
      path: '/accounts'
      fullPath: '/accounts'
      preLoaderRoute: typeof AccountsLazyImport
      parentRoute: typeof rootRoute
    }
    '/forgot-password': {
      id: '/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof ForgotPasswordLazyImport
      parentRoute: typeof rootRoute
    }
    '/profile': {
      id: '/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof ProfileLazyImport
      parentRoute: typeof rootRoute
    }
    '/reset-password': {
      id: '/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof ResetPasswordLazyImport
      parentRoute: typeof rootRoute
    }
    '/customers/$customerId': {
      id: '/customers/$customerId'
      path: '/customers/$customerId'
      fullPath: '/customers/$customerId'
      preLoaderRoute: typeof CustomersCustomerIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/customers/': {
      id: '/customers/'
      path: '/customers'
      fullPath: '/customers'
      preLoaderRoute: typeof CustomersIndexLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/login': typeof LoginRoute
  '/signup': typeof SignupRoute
  '/accounts': typeof AccountsLazyRoute
  '/forgot-password': typeof ForgotPasswordLazyRoute
  '/profile': typeof ProfileLazyRoute
  '/reset-password': typeof ResetPasswordLazyRoute
  '/customers/$customerId': typeof CustomersCustomerIdLazyRoute
  '/customers': typeof CustomersIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/login': typeof LoginRoute
  '/signup': typeof SignupRoute
  '/accounts': typeof AccountsLazyRoute
  '/forgot-password': typeof ForgotPasswordLazyRoute
  '/profile': typeof ProfileLazyRoute
  '/reset-password': typeof ResetPasswordLazyRoute
  '/customers/$customerId': typeof CustomersCustomerIdLazyRoute
  '/customers': typeof CustomersIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/login': typeof LoginRoute
  '/signup': typeof SignupRoute
  '/accounts': typeof AccountsLazyRoute
  '/forgot-password': typeof ForgotPasswordLazyRoute
  '/profile': typeof ProfileLazyRoute
  '/reset-password': typeof ResetPasswordLazyRoute
  '/customers/$customerId': typeof CustomersCustomerIdLazyRoute
  '/customers/': typeof CustomersIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/login'
    | '/signup'
    | '/accounts'
    | '/forgot-password'
    | '/profile'
    | '/reset-password'
    | '/customers/$customerId'
    | '/customers'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/login'
    | '/signup'
    | '/accounts'
    | '/forgot-password'
    | '/profile'
    | '/reset-password'
    | '/customers/$customerId'
    | '/customers'
  id:
    | '__root__'
    | '/'
    | '/login'
    | '/signup'
    | '/accounts'
    | '/forgot-password'
    | '/profile'
    | '/reset-password'
    | '/customers/$customerId'
    | '/customers/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  LoginRoute: typeof LoginRoute
  SignupRoute: typeof SignupRoute
  AccountsLazyRoute: typeof AccountsLazyRoute
  ForgotPasswordLazyRoute: typeof ForgotPasswordLazyRoute
  ProfileLazyRoute: typeof ProfileLazyRoute
  ResetPasswordLazyRoute: typeof ResetPasswordLazyRoute
  CustomersCustomerIdLazyRoute: typeof CustomersCustomerIdLazyRoute
  CustomersIndexLazyRoute: typeof CustomersIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  LoginRoute: LoginRoute,
  SignupRoute: SignupRoute,
  AccountsLazyRoute: AccountsLazyRoute,
  ForgotPasswordLazyRoute: ForgotPasswordLazyRoute,
  ProfileLazyRoute: ProfileLazyRoute,
  ResetPasswordLazyRoute: ResetPasswordLazyRoute,
  CustomersCustomerIdLazyRoute: CustomersCustomerIdLazyRoute,
  CustomersIndexLazyRoute: CustomersIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/login",
        "/signup",
        "/accounts",
        "/forgot-password",
        "/profile",
        "/reset-password",
        "/customers/$customerId",
        "/customers/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/signup": {
      "filePath": "signup.tsx"
    },
    "/accounts": {
      "filePath": "accounts.lazy.tsx"
    },
    "/forgot-password": {
      "filePath": "forgot-password.lazy.tsx"
    },
    "/profile": {
      "filePath": "profile.lazy.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.lazy.tsx"
    },
    "/customers/$customerId": {
      "filePath": "customers/$customerId.lazy.tsx"
    },
    "/customers/": {
      "filePath": "customers/index.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
