import { Box, Container } from '@mui/material'
import ErrorBoundary from 'components/common/ErrorBoundary'
import { PropsWithChildren } from 'react'

interface AuthLayoutProps extends PropsWithChildren {}

export function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <ErrorBoundary>
      <Box
        sx={{
          minHeight: '100dvh',
          backgroundImage: {
            sm: 'url(images/bottom-illustration.png)',
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: {
            xxl: '1700px',
            sm: '100%',
          },
        }}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              pt: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: 0,
            }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </ErrorBoundary>
  )
}
