import { STORAGE_KEYS } from 'constants/key'
import { accountService } from './account'
import { authService } from './auth'
import { customerService } from './customer'
import { invitationService } from './invitation'
import { subscriptionService } from './subscription'
import { uploadService } from './upload'

const authServices = [
  authService,
  customerService,
  subscriptionService,
  accountService,
  invitationService,
  uploadService,
]

const services = {
  setAuthToken: (token: string) => {
    authServices.forEach((service) => service.setAuthToken(token))
    localStorage.setItem(STORAGE_KEYS.AccessToken, token)
  },

  clearAuthToken: () => {
    authServices.forEach((service) => service.clearAuthToken())
    localStorage.removeItem(STORAGE_KEYS.AccessToken)
  },
  getAuthToken: () => {
    return (
      authServices[0].accessToken ||
      localStorage.getItem(STORAGE_KEYS.AccessToken)
    )
  },
}

export default services
