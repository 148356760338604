import { UpdateAdminInvitationRequest } from './admin-schema'
import { Client } from './client'
import fetcher from './fetcher'
import {
  CreateAdminInviteRequest,
  GetInvitationDetailsResponse,
} from './schema'
import { BaseResponse } from './types'

class InvitationService extends Client {
  public getInvitationByCode(code: string) {
    return fetcher<GetInvitationDetailsResponse>(
      `${this.baseUrl}/api/v1/admin/invitations/${code}`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public inviteAccount({ email, roleID }: CreateAdminInviteRequest) {
    return fetcher(`${this.baseUrl}/api/v1/admin/invitations`, {
      method: 'POST',
      headers: this.privateHeaders,
      body: JSON.stringify({ email, roleID }),
    })
  }

  public acceptInvitation(code: string) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/api/v1/admin/invitations/accept`,
      {
        method: 'POST',
        headers: this.privateHeaders,
        body: JSON.stringify({ token: code }),
      },
    )
  }

  public cancelInvitation(invitationId: string) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/api/v1/admin/invitations/${invitationId}`,
      {
        method: 'DELETE',
        headers: this.privateHeaders,
      },
    )
  }
  public updateInvitation(
    invitationId: string,
    data: UpdateAdminInvitationRequest,
  ) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/api/v1/admin/invitations/${invitationId}`,
      {
        method: 'PUT',
        headers: this.privateHeaders,
        body: JSON.stringify(data),
      },
    )
  }
}

const invitationService = new InvitationService()

export { invitationService }
