export type CustomerParams = {
  customerId: string
}

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CUSTOMERS: '/customers',
  ACCOUNTS: '/accounts',
  CUSTOMER: (params: CustomerParams) => `/customers/${params.customerId}`,
  PROFILE: '/profile',
}

export const NO_AUTH_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.SIGN_UP,
]
