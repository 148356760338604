import React from 'react'
import {
  Controller,
  FieldValues,
  UseControllerProps,
  get,
  useFormContext,
} from 'react-hook-form'

// This HOC function takes a Material-UI form component and returns a new component
// that is controlled by react-hook-form.
export function withReactHookForm<T extends FieldValues>(
  Component: React.ComponentType<T>,
) {
  // The returned component accepts all props that the original Material-UI component
  // would accept, plus the additional props required by react-hook-form.
  return function ReactHookFormComponent(
    props: T & { controllerProps?: UseControllerProps; name?: string },
  ) {
    const { controllerProps, ...componentProps } = props
    const {
      formState: { errors },
    } = useFormContext()

    const controllerName = controllerProps?.name || componentProps?.name || ''

    const isError = Boolean(get(errors, controllerName))

    return (
      <Controller
        defaultValue={controllerProps?.defaultValue}
        name={controllerName}
        render={({ field }) => (
          <Component
            error={isError}
            helperText={get(errors, controllerName)?.message}
            {...field}
            {...(componentProps as T)}
            onChange={(...arg: any[]) => {
              field.onChange(...arg)
              componentProps.onChange?.(...arg)
            }}
          />
        )}
        rules={controllerProps?.rules}
        shouldUnregister={controllerProps?.shouldUnregister}
      />
    )
  }
}
