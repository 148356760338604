import { useFetchWithCache } from 'hooks/useFetchWithCache'
import { invitationService } from 'services/invitation'

const KEY = '/api/v1/admin/invitations/:id'

export const useFetchInvitation = (code: string) => {
  return useFetchWithCache(
    code ? [KEY, code] : null,
    () => {
      return invitationService.getInvitationByCode(code)
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  )
}
