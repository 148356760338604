import { createFileRoute } from '@tanstack/react-router'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormTextField } from 'components/form/FormTextField'
import { ROUTES } from 'constants/routes'
import { FormProvider, useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Grid2, Stack } from '@mui/material'
import MuiLink from '@mui/material/Link'
import { Link } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { toast } from 'components/Toast'
import { AuthLayout } from 'components/layouts/AuthLayout'
import { useAuthContext } from 'contexts/auth'
import * as z from 'zod'

export const Route = createFileRoute('/login')({
  component: Login,
  validateSearch: zodSearchValidator(
    z.object({
      continue: z.string().optional(),
    }),
  ),
})

const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: 'Required' })
    .email('Invalid email'),
  password: z.string().trim().min(1, { message: 'Required' }),
})

function Copyright(props: any) {
  return (
    <Typography
      align="center"
      color="text.secondary"
      variant="body2"
      {...props}
    >
      {'Copyright © '}
      <MuiLink color="inherit" href="/">
        Umbrella Product
      </MuiLink>{' '}
      {new Date().getFullYear()}.
    </Typography>
  )
}

interface LoginFormValues {
  email: string
  password: string
}

export default function Login() {
  const { login, isLogging } = useAuthContext()
  const searchParams = Route.useSearch()

  const navigate = Route.useNavigate()
  const { continue: continueUrlParam } = searchParams

  const formInstance = useForm<LoginFormValues>({
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formInstance
  const onSubmit = async (values: LoginFormValues) => {
    try {
      await login(values)
      if (
        typeof continueUrlParam === 'string' &&
        continueUrlParam.startsWith('/')
      ) {
        navigate({ to: continueUrlParam })
        return
      }
      navigate({
        to: ROUTES.HOME,
      })
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  return (
    <AuthLayout>
      <Box sx={{ maxWidth: 300 }}>
        <img alt="logo" src="images/logo-grey.png" />
      </Box>

      <Box sx={{ mt: 2 }}>
        <FormProvider {...formInstance}>
          <Box
            component="form"
            sx={{ mt: 1 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormTextField
              autoComplete="email"
              id="email"
              label="Email Address"
              margin="normal"
              name="email"
              autoFocus
              fullWidth
              required
            />
            <FormTextField
              autoComplete="current-password"
              id="password"
              label="Password"
              margin="normal"
              name="password"
              type="password"
              fullWidth
              required
            />
            <LoadingButton
              disabled={isLogging}
              loading={isSubmitting}
              size="large"
              sx={{ mt: 3, mb: 1 }}
              type="submit"
              variant="contained"
              fullWidth
            >
              Log in
            </LoadingButton>
            <Grid2 container>
              <Grid2>
                <MuiLink
                  component={Link}
                  href={ROUTES.FORGOT_PASSWORD}
                  variant="body2"
                >
                  Forgot password?
                </MuiLink>
              </Grid2>
            </Grid2>
          </Box>
        </FormProvider>
      </Box>

      <Stack spacing={1} sx={{ mt: 8, mb: 4 }}>
        <Copyright />
      </Stack>
    </AuthLayout>
  )
}
