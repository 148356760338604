import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { FormTextField } from 'components/form/FormTextField'
import { ROUTES } from 'constants/routes'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Card, CircularProgress, Stack } from '@mui/material'
import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { HeadMeta } from 'components/common/HeadMeta'
import { AuthLayout } from 'components/layouts/AuthLayout'
import { toast } from 'components/Toast'
import { useAuthContext } from 'contexts/auth'
import { useFetchInvitation } from 'hooks/invitations/useFetchInvitation'
import * as z from 'zod'

export const Route = createFileRoute('/signup')({
  component: SignUp,
  validateSearch: zodSearchValidator(
    z.object({
      continue: z.string().optional(),
      code: z.string().optional(),
      first_name: z.string().optional(),
      last_name: z.string().optional(),
    }),
  ),
})

const schema = z
  .object({
    email: z
      .string()
      .trim()
      .min(1, { message: 'Required' })
      .email('Invalid email'),
    password: z
      .string()
      .trim()
      .min(8, 'Password must be at least 8 characters long')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character',
      ),
    confirmPassword: z.string().trim().min(1, { message: 'Required' }),
    firstName: z.string().min(1, { message: 'Required' }),
    lastName: z.string().min(1, { message: 'Required' }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })

function Copyright(props: any) {
  return (
    <Typography
      align="center"
      color="text.secondary"
      variant="body2"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="/">
        Umbrella Product
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  )
}

interface LoginFormValues {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
}

export default function SignUp() {
  const { signUp, isLogging } = useAuthContext()
  const navigate = Route.useNavigate()
  const {
    continue: continueUrlParam,
    code: invitationCode,
    first_name: initFirstName,
    last_name: initLastName,
  } = Route.useSearch()

  const {
    data,
    isLoading: isLoadingInvitation,
    error: invitationError,
  } = useFetchInvitation(invitationCode as string)
  const invitation = data?.data

  const formInstance = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      firstName: initFirstName as string,
      lastName: initLastName as string,
    },
    resolver: zodResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = formInstance

  // Update form when invitation data is loaded
  useEffect(() => {
    if (invitation?.email) {
      setValue('email', invitation.email)
    }
  }, [invitation, setValue])

  const onSubmit = async (values: LoginFormValues) => {
    try {
      await signUp({
        ...values,
        invitationCode: invitation?.token as string,
      })

      if (
        typeof continueUrlParam === 'string' &&
        continueUrlParam.startsWith('/')
      ) {
        navigate({ to: continueUrlParam })
        return
      }

      navigate({ to: ROUTES.HOME })
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  if (isLoadingInvitation && invitationCode) {
    return (
      <AuthLayout>
        <HeadMeta title="Sign Up" />
        <CircularProgress />
      </AuthLayout>
    )
  }
  if (!invitationCode || invitationError) {
    return (
      <Box
        sx={{
          background: (theme) => theme.untitled.colors.gray[50],
        }}
      >
        <AuthLayout>
          <HeadMeta title="Sign Up" />
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 400,
            }}
          >
            <Card sx={{ textAlign: 'center', maxWidth: 600, mx: 'auto', p: 4 }}>
              <Typography variant="h4" gutterBottom>
                Invitation not found
              </Typography>
              <Button
                LinkComponent={Link}
                href={ROUTES.HOME}
                sx={{ mt: 2 }}
                variant="text"
              >
                Back to home
              </Button>
            </Card>
          </Box>
        </AuthLayout>
      </Box>
    )
  }

  return (
    <AuthLayout>
      <HeadMeta title="Sign Up" />

      <Box sx={{ maxWidth: 300 }}>
        <img alt="logo" src="images/logo-grey.png" />
      </Box>

      <Box sx={{ mt: 2 }}>
        <FormProvider {...formInstance}>
          <Box
            component="form"
            sx={{ mt: 3 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
              <FormTextField
                autoComplete="firstName"
                id="firstName"
                label="First Name"
                margin="normal"
                name="firstName"
                autoFocus
                fullWidth
                required
              />
              <FormTextField
                autoComplete="lastName"
                id="lastName"
                label="Last Name"
                margin="normal"
                name="lastName"
                fullWidth
                required
              />
            </Stack>

            <FormTextField
              disabled={!!invitation?.email}
              id="email"
              label="Email Address"
              margin="normal"
              name="email"
              value={invitation?.email || undefined}
              fullWidth
              required
            />
            <FormTextField
              id="password"
              label="Password"
              margin="normal"
              name="password"
              type="password"
              fullWidth
              required
            />
            <FormTextField
              id="confirmPassword"
              label="Confirm Password"
              margin="normal"
              name="confirmPassword"
              type="password"
              fullWidth
              required
            />
            <LoadingButton
              disabled={isSubmitting || isLogging}
              loading={isSubmitting || isLogging}
              size="large"
              sx={{ mt: 3, mb: 1 }}
              type="submit"
              variant="contained"
              fullWidth
            >
              Sign Up
            </LoadingButton>
            <Grid container>
              <Grid item xs />
              <Grid item>
                <Link href={ROUTES.LOGIN} variant="body2">
                  Already have an account? Log In
                </Link>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </Box>

      <Copyright sx={{ mt: 8, mb: 4 }} />
    </AuthLayout>
  )
}
