export function clearLocalStorageExcept(keysToKeep: string[] = []) {
  if (!keysToKeep.length) {
    localStorage.clear()
    return
  }

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i) as string
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key)
    }
  }
}
