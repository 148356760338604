export default function FeaturedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          d="M6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32C11.8203 32 6 26.1797 6 19Z"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <g clipPath="url(#clip0_4944_371099)">
        <path
          d="M19 22.3333V19M19 15.6666H19.0084M27.3334 19C27.3334 23.6023 23.6024 27.3333 19 27.3333C14.3976 27.3333 10.6667 23.6023 10.6667 19C10.6667 14.3976 14.3976 10.6666 19 10.6666C23.6024 10.6666 27.3334 14.3976 27.3334 19Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.66667"
        />
      </g>
      <defs>
        <clipPath id="clip0_4944_371099">
          <rect
            fill="white"
            height="20"
            transform="translate(9 9)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
