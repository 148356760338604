import { getQueryString } from 'utils/queryParams'
import { Client } from './client'
import fetcher from './fetcher'

import { GetCustomerResponse, GetCustomersResponse } from './admin-schema'
import { GetCustomerQuery, GetCustomersQuery } from './types/customer'

class CustomerService extends Client {
  public getCustomers(query: GetCustomersQuery) {
    return fetcher<GetCustomersResponse>(
      `${this.baseUrl}/api/v1/admin/customers${getQueryString(query, true)}`,
      {
        headers: this.privateHeaders,
      },
    )
  }
  public getCustomer(query: GetCustomerQuery) {
    return fetcher<GetCustomerResponse>(
      `${this.baseUrl}/api/v1/admin/customers/${query.customerId}`,
      {
        headers: this.privateHeaders,
      },
    )
  }
}

const customerService = new CustomerService()

export { customerService }
