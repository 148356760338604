import { useCallback, useEffect, useState } from 'react'
import { FetcherError } from 'services/fetcher'
import useSWR, { Fetcher, Key, SWRConfiguration } from 'swr'

export function useFetchWithCache<
  Data = any,
  Error extends FetcherError = FetcherError,
>(
  key: Key,
  fn: Fetcher<Data> | null = null,
  config?: SWRConfiguration<Data, Error>,
) {
  const { data, error, ...rest } = useSWR<Data, Error>(key, fn, {
    ...config,
    onErrorRetry(err, _, __, revalidate, { retryCount }) {
      // Never retry
      if (err.statusCode >= 400 && err.statusCode < 500) {
        return
      }
      // Max 3 times
      if (retryCount >= 3) return
      setTimeout(() => revalidate({ retryCount }), 3000 * retryCount)
    },
  })

  const [internalData, setInternalData] = useState<Data | undefined>(data)
  const [loading, setLoading] = useState(!data && !error)

  const isFirstLoading = !internalData && !error
  const clearInternalData = useCallback(() => {
    setInternalData(undefined)
  }, [])

  useEffect(() => {
    setLoading(!data && !error)
  }, [data, error])

  useEffect(() => {
    if (data) {
      setInternalData(data)
    }
  }, [data])

  return {
    data: internalData,
    isFirstLoading,
    loading, // true if data is not available or not fetching
    error,
    ...rest,
    isLoading: !!key && loading, // true only when fetching data
    clearInternalData,
    isNotFound:
      error?.statusCode && error.statusCode < 500 && error.statusCode >= 400,
  }
}
