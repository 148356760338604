import { Client } from './client'
import fetcher from './fetcher'
import { GetAdminAccountsResponse, GetAdminRolesResponse } from './schema'

class AdminAccountService extends Client {
  public getAccounts() {
    return fetcher<GetAdminAccountsResponse>(
      `${this.baseUrl}/api/v1/admin/accounts`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public activateAccount(id: string) {
    return fetcher(`${this.baseUrl}/api/v1/admin/accounts/${id}/active`, {
      method: 'PUT',
      headers: this.privateHeaders,
    })
  }

  public deactivateAccount(id: string) {
    return fetcher(`${this.baseUrl}/api/v1/admin/accounts/${id}/deactive`, {
      method: 'PUT',
      headers: this.privateHeaders,
    })
  }

  public getRoles() {
    return fetcher<GetAdminRolesResponse>(
      `${this.baseUrl}/api/v1/admin/roles`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public updateAccount(accountId: string, roleID: string) {
    return fetcher(`${this.baseUrl}/api/v1/admin/accounts/${accountId}`, {
      method: 'PUT',
      headers: this.privateHeaders,
      body: JSON.stringify({
        roleID,
      }),
    })
  }
}

const accountService = new AdminAccountService()

export { accountService }
