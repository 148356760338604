export default function FeaturedCheckIcon(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          d="M18.0001 30.5C11.0965 30.5 5.50008 24.9035 5.50008 18C5.50008 11.0964 11.0965 5.49996 18.0001 5.49996C24.9036 5.49996 30.5001 11.0964 30.5001 18C30.5001 24.9035 24.9036 30.5 18.0001 30.5Z"
          stroke="currentColor"
          strokeWidth="1.66667"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M17.1667 1.33333H18.8333C27.5778 1.33333 34.6667 8.42216 34.6667 17.1667V18.8333C34.6667 27.5778 27.5778 34.6667 18.8333 34.6667H17.1667C8.42216 34.6667 1.33333 27.5778 1.33333 18.8333V17.1667C1.33333 8.42216 8.42216 1.33333 17.1667 1.33333Z"
          stroke="currentColor"
          strokeWidth="1.66667"
        />
      </g>
      <g clipPath="url(#clip0_1130_82911)">
        <path
          d="M14.2501 18L16.7501 20.5L21.7501 15.5M26.3334 18C26.3334 22.6023 22.6025 26.3333 18.0001 26.3333C13.3977 26.3333 9.66675 22.6023 9.66675 18C9.66675 13.3976 13.3977 9.66663 18.0001 9.66663C22.6025 9.66663 26.3334 13.3976 26.3334 18Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.66667"
        />
      </g>
      <defs>
        <clipPath id="clip0_1130_82911">
          <rect
            fill="white"
            height="20"
            transform="translate(8 8)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
