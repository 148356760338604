import {
  AdminDetail,
  LoginAdminRequest,
  SignUpAdminRequest,
  UpdateAdminProfileRequest,
} from './admin-schema'
import { Client } from './client'
import fetcher from './fetcher'
import {
  AuthResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UserProfile,
} from './schema'
import { BaseResponse } from './types'

class AuthService extends Client {
  public login(params: LoginAdminRequest) {
    return fetcher<AuthResponse>(`${this.baseUrl}/api/v1/admin/auth/login`, {
      body: JSON.stringify(params),
      method: 'POST',
      headers: this.headers,
    })
  }

  public signUp(params: SignUpAdminRequest) {
    return fetcher<AuthResponse>(`${this.baseUrl}/api/v1/admin/auth/signup`, {
      body: JSON.stringify(params),
      method: 'POST',
      headers: this.headers,
    })
  }

  public logout() {
    return fetcher(`${this.baseUrl}/api/v1/admin/auth/logout`, {
      cache: 'no-store',
      headers: this.privateHeaders,
    })
  }

  public getMe() {
    return fetcher<BaseResponse<UserProfile>>(
      `${this.baseUrl}/api/v1/admin/auth/me`,
      {
        headers: this.privateHeaders,
      },
    )
  }

  public updateProfile(body: UpdateAdminProfileRequest) {
    return fetcher<BaseResponse<AdminDetail>>(
      `${this.baseUrl}/api/v1/admin/auth/profile`,
      {
        headers: this.privateHeaders,
        method: 'PUT',
        body: JSON.stringify(body),
      },
    )
  }

  public forgotPassword(params: ForgotPasswordRequest) {
    return fetcher<BaseResponse<ForgotPasswordResponse>>(
      `${this.baseUrl}/api/v1/admin/auth/forgot-password`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }

  public resetPassword(params: ResetPasswordRequest) {
    return fetcher<BaseResponse<ResetPasswordResponse>>(
      `${this.baseUrl}/api/v1/admin/auth/reset-password`,
      {
        headers: this.headers,
        method: 'POST',
        body: JSON.stringify(params),
      },
    )
  }
}

const authService = new AuthService()

export { authService }
