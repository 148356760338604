type Headers = Record<string, string>

export class Client {
  headers: Headers = {
    'Content-Type': 'application/json',
  }
  accessToken: string = ''

  privateHeaders: Headers = {
    ...this.headers,
    credentials: 'include',
  }

  baseUrl: string = import.meta.env.VITE_BASE_URL || ''

  public get formDataHeaders(): Headers {
    const cloned = Object.assign({}, this.privateHeaders)
    // Browsers will auto-set Content-Type and other things when formData is used
    // Content-Type must not be present for form data to work
    delete cloned['Content-Type']

    return cloned
  }
  public setAuthToken(token: string) {
    this.privateHeaders = {
      ...this.privateHeaders,
      Authorization: `Bearer ${token}`,
    }
    this.accessToken = token
  }

  public clearAuthToken() {
    this.privateHeaders = { ...this.headers }
    this.accessToken = ''
  }
}

const client = new Client()

export { client }
