import { EndEnterprisePlanRequest, OverridePlanRequest } from './admin-schema'
import { Client } from './client'
import fetcher from './fetcher'
import { BaseResponse } from './types'

class SubscriptionService extends Client {
  public upComingInvoiceEstimateV2(subID: string) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/api/v2/subscriptions/${subID}/upcoming-invoice`,
      {
        headers: this.privateHeaders,
        method: 'GET',
      },
    )
  }
  public overrideEnterprisePlan(payload: OverridePlanRequest) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/api/v2/admin/subscriptions/plans/overrides`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(payload),
      },
    )
  }
  public endEnterprisePlan(payload: EndEnterprisePlanRequest) {
    return fetcher<BaseResponse<any>>(
      `${this.baseUrl}/api/v2/admin/subscriptions/plans/end-enterprise`,
      {
        headers: this.privateHeaders,
        method: 'POST',
        body: JSON.stringify(payload),
      },
    )
  }
}

const subscriptionService = new SubscriptionService()

export { subscriptionService }
