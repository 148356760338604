import { Link as MUILink, Stack, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { Link } from '@tanstack/react-router'
import { ROUTES } from 'constants/routes'
import * as React from 'react'

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log({ error, errorInfo })
    Sentry.captureException(error, { extra: { errorInfo } })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Stack
            alignItems="center"
            direction="column"
            justifyContent="center"
            spacing={2}
            sx={{
              mb: 4,
              mt: '15dvh',
              p: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h4">An error occurred</Typography>
            <Typography variant="body1">
              Sorry, something went wrong while processing your request.
              <br />
              Please try reloading the page or navigate back to the{' '}
              <MUILink component={Link} href={ROUTES.HOME}>
                home page
              </MUILink>
              .
            </Typography>
          </Stack>
        </div>
      )
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default ErrorBoundary
