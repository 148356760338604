import { Stack, Typography } from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert'

import { createContext } from '@dwarvesf/react-utils'
import Snackbar, {
  SnackbarCloseReason,
  SnackbarProps,
} from '@mui/material/Snackbar'
import FeaturedCheckIcon from 'components/icons/FeaturedCheckIcon'
import FeaturedIcon from 'components/icons/FeaturedIcon'
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

const DEFAULT_DURATION = 3000 // 3 seconds

interface ToastContextValue {
  success: (msg?: string, desc?: string, config?: SnackbarProps) => void
  error: (msg?: string, desc?: string, config?: SnackbarProps) => void
  warning: (msg?: string, desc?: string, config?: SnackbarProps) => void
  info: (msg?: string, desc?: string, config?: SnackbarProps) => void
}

const [Provider] = createContext<ToastContextValue>({
  name: 'ToastContext',
})

const toast: ToastContextValue = {
  success: () => {},
  error: () => {},
  warning: () => {},
  info: () => {},
}

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [description, setDescription] = useState('')
  const [severity, setSeverity] = useState<AlertProps['severity']>('info')
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarProps>()

  const handleClose = (
    _event: Event | SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const showToast = useCallback(
    (
      msg: string,
      description: string,
      type: AlertProps['severity'] = 'info',
      config?: SnackbarProps,
    ) => {
      setOpen(false)
      setTimeout(() => {
        if (msg && !description) {
          setMessage('')
          setDescription(msg)
        } else {
          setMessage(msg)
          setDescription(description)
        }
        setSeverity(type)
        setSnackbarConfig(config)
        setOpen(true)
      })
    },
    [],
  )

  const success = useCallback(
    (msg?: string, desc?: string, config?: SnackbarProps) => {
      showToast(msg || '', desc || '', 'success', config)
    },
    [showToast],
  )
  const error = useCallback(
    (msg?: string, desc?: string, config?: SnackbarProps) => {
      showToast(msg || '', desc || '', 'error', config)
    },
    [showToast],
  )
  const warning = useCallback(
    (msg?: string, desc?: string, config?: SnackbarProps) => {
      showToast(msg || '', desc || '', 'warning', config)
    },
    [showToast],
  )
  const info = useCallback(
    (msg?: string, desc?: string, config?: SnackbarProps) => {
      showToast(msg || '', desc || '', 'info', config)
    },
    [showToast],
  )

  const contextValue = useMemo(
    () => ({ success, error, warning, info }),
    [success, error, warning, info],
  )

  useEffect(() => {
    Object.assign(toast, contextValue)
  }, [contextValue])

  return (
    <Provider value={contextValue}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={DEFAULT_DURATION}
        open={open}
        onClose={handleClose}
        {...snackbarConfig}
      >
        <Alert
          elevation={6}
          iconMapping={{
            info: (
              <FeaturedIcon
                style={{
                  width: '38px',
                  height: '38px',
                  margin: '-9px',
                }}
              />
            ),
            success: (
              <FeaturedCheckIcon
                style={{
                  width: '38px',
                  height: '38px',
                  margin: '-9px',
                }}
              />
            ),
            error: (
              <FeaturedIcon
                style={{
                  width: '38px',
                  height: '38px',
                  margin: '-9px',
                }}
              />
            ),
            warning: (
              <FeaturedIcon
                style={{
                  width: '38px',
                  height: '38px',
                  margin: '-9px',
                }}
              />
            ),
          }}
          severity={severity}
          sx={{
            maxWidth: {
              xs: 'calc(100vw - 16px)',
              sm: '400px',
            },
          }}
          variant="outlined"
          onClose={() => setOpen(false)}
        >
          <Stack sx={{ width: '100%' }}>
            <Typography
              fontSize={14}
              fontWeight={600}
              sx={{
                color: (theme) => theme.untitled.colors.text.primary,
                overflow: 'hidden',
              }}
              noWrap
            >
              {message}
            </Typography>
            {description && (
              <Typography
                fontSize={14}
                sx={{
                  color: (theme) => theme.untitled.colors.text.tertiary,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {description}
              </Typography>
            )}
          </Stack>
        </Alert>
      </Snackbar>
    </Provider>
  )
}

export { toast, ToastProvider }
