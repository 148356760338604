import { useAsyncEffect } from '@dwarvesf/react-hooks'
import { createContext } from '@dwarvesf/react-utils'
import { useNavigate } from '@tanstack/react-router'
import { toast } from 'components/Toast'
import { AdminPermission } from 'constants/adminRole'
import { KeysToKeepInLocalStorage } from 'constants/key'
import { NO_AUTH_ROUTES, ROUTES } from 'constants/routes'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import services from 'services'
import {
  AdminDetail,
  LoginAdminRequest,
  SignUpAdminRequest,
} from 'services/admin-schema'
import { authService } from 'services/auth'
import emitter, { API_REQUEST } from 'services/emitter'
import { mutate } from 'swr'
import { clearLocalStorageExcept } from 'utils/localStorage'

interface AuthContextValues {
  isLogin: boolean
  isLogging: boolean
  login: (params: LoginAdminRequest) => Promise<any>
  signUp: (params: SignUpAdminRequest) => Promise<any>
  logout: () => Promise<any>
  user?: AdminDetail
  refreshUser: () => Promise<any>
  checkPermission: (permission: AdminPermission) => boolean
}

const [Provider, useAuthContext] = createContext<AuthContextValues>({
  name: 'auth',
})

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<AdminDetail>()
  const [isLogging, setIsLogging] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const navigate = useNavigate()
  const refreshUser = useCallback(async () => {
    try {
      const data = await authService.getMe()
      if (data.data) {
        setUser(data.data as any)
        return data.data
      }
    } catch (error: any) {
      console.error(error)
      return null
    }
  }, [])

  const login = useCallback(async ({ email, password }: LoginAdminRequest) => {
    try {
      setIsLogging(true)
      const res = await authService.login({ email, password })
      services.setAuthToken(res.accessToken)

      const data = await authService.getMe()
      if (data.data) {
        setUser(data.data as any)
        setIsLogin(true)
        // pushLoginEvent({
        //   userEmail: email as string,
        //   userId: data.data.id as string,
        //   method: 'email',
        // })
      }
    } catch (error: any) {
      throw new Error(error?.message)
    } finally {
      setIsLogging(false)
    }
  }, [])

  const signUp = useCallback(async (req: SignUpAdminRequest) => {
    try {
      setIsLogging(true)
      const data = await authService.signUp(req)
      services.setAuthToken(data.accessToken)
      const profileResp = await authService.getMe()
      if (profileResp.data) {
        setUser(profileResp.data as any)
        setIsLogin(true)
        services.setAuthToken(data.accessToken)
        /*   pushSignupEvent({
          userEmail: profileResp?.data?.email as string,
          userId: profileResp?.data?.id as string,
          method: 'email',
        }) */
      }
      return data
    } catch (error: any) {
      toast.error(error?.message)
      throw new Error(error?.message)
    } finally {
      setIsLogging(false)
    }
  }, [])

  const logout = useCallback(async () => {
    if (authService.accessToken) {
      await authService.logout()
    }
    // Clear all SWR cache
    services.clearAuthToken()
    clearLocalStorageExcept(KeysToKeepInLocalStorage)
    // https://github.com/vercel/swr/issues/1887#issuecomment-1197350740
    await mutate(() => true, undefined, false)

    setUser(undefined)
    setIsLogin(false)
  }, [])
  const checkPermission = useCallback(
    (permission: AdminPermission) => {
      return user?.role?.permissions.includes(permission) ?? false
    },
    [user?.role?.permissions],
  )

  useAsyncEffect(async () => {
    const token = services.getAuthToken()
    if (token) {
      services.setAuthToken(token)
      try {
        setIsLogging(true)
        const data = await authService.getMe()

        if (data.data) {
          setUser(data.data as any)
          setIsLogin(true)
        }
      } catch (error: any) {
        console.error(error)
      } finally {
        setIsLogging(false)
      }
      return
    }
    if (
      !NO_AUTH_ROUTES.some((route) => window.location.pathname.includes(route))
    ) {
      window.location.href = ROUTES.LOGIN
    }
  }, [])

  useEffect(() => {
    emitter.on(API_REQUEST, async (data: any) => {
      if (data.status === 401) {
        await logout()
        navigate({ to: ROUTES.LOGIN })
      }
    })
    return () => {
      emitter.off(API_REQUEST)
    }
  }, [logout, navigate])

  return (
    <Provider
      value={{
        isLogin,
        login,
        logout,
        user,
        isLogging,
        signUp,
        refreshUser,
        checkPermission,
      }}
    >
      {children}
    </Provider>
  )
}

export { AuthContextProvider, useAuthContext }
