import '@fontsource/manrope/400.css'
import '@fontsource/manrope/500.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'
import { blue, green, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const manrope = {
  fontFamily: 'Manrope, sans-serif',
  fontWeights: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
}

export const untitled = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    primary: '#43C19B',
    transparent: 'transparent',
    divider: '#E5E5E5',
    dividerPrimary: '#D6D6D6',
    text: {
      primary: '#141414',
      secondary: '#737373',
      tertiary: '#525252',
      disabled: '#A3A3A3',
      placeholder: '#737373',
      danger: '#F97066',
      brand: {
        secondary: '#52CDA8',
      },
    },
    brand: {
      '25': '#ECFFF9',
      '50': '#DDFEF4',
      '100': '#BDF3E3',
      '200': '#B0F1DD',
      '300': '#A3EFD8',
      '400': '#89EACC',
      '500': '#7BE7C6',
      '600': '#66DBB7',
      '700': '#66DBB7',
      '800': '#39C79C',
      '900': '#38BD95',
      '950': '#10996F',
    },
    border: {
      primary: '#D6D6D6',
      brand: {
        solid: '#52CDA8',
      },
    },
    background: {
      secondary: '#FAFAFA',
    },
    estaban: {
      '25': '#F6FEFC',
      '50': '#DDF3FF',
      '100': '#AAE1FE',
      '200': '#99DAFE',
      '300': '#88D5FE',
      '400': '#66C9FE',
      '500': '#55C2FD',
      '600': '#0E9384',
      '700': '#3CA9E5',
      '800': '#125D56',
      '900': '#2297D7',
      '950': '#0A2926',
    },
    gray: {
      '25': '#FCFCFC',
      '50': '#FAFAFA',
      '100': '#F5F5F5',
      '200': '#E5E5E5',
      '300': '#D6D6D6',
      '400': '#A3A3A3',
      '500': '#737373',
      '600': '#525252',
      '700': '#424242',
      '800': '#292929',
      '900': '#141414',
      '950': '#0F0F0F',
    },
    success: {
      '25': '#F6FEF9',
      '50': '#ECFDF3',
      '100': '#DCFCE7',
      '200': '#BBF7D0',
      '300': '#86EFAC',
      '400': '#4ADE80',
      '500': '#22C55E',
      '600': '#16A34A',
      '700': '#15803D',
      '800': '#166534',
      '900': '#14532D',
      '950': '#134E4A',
    },
    warning: {
      '25': '#FFFCF5',
      '50': '#FFFAEB',
      '100': '#FEF0C7',
      '200': '#FEDF89',
      '300': '#FEC84B',
      '400': '#FDB022',
      '500': '#F79009',
      '600': '#DC6803',
      '700': '#B54708',
      '800': '#93370D',
      '900': '#7A2E0E',
      '950': '#4E1D09',
    },
    error: {
      '25': '#FFF8F6',
      '50': '#FEEFEC',
      '100': '#FECEC4',
      '200': '#FD9B90',
      '300': '#F6756E',
      '400': '#E74E3C',
      '500': '#D53822',
      '600': '#BF0A15',
      '700': '#8F0C1C',
      '800': '#6F141E',
      '900': '#4B151C',
      '950': '#291416',
    },
  },
  shadow: {
    xs: '0px 1px 2px 0px #1018280D',
    sm: '0px 1px 3px 0px #1018281A',
    md: '0px 4px 8px -2px #1018281A',
    lg: '0px 12px 16px -4px #10182814',
    xl: '0px 20px 24px -4px #10182814',
    '2xl': '0px 24px 48px -12px #1018282E',
    '3xl': '0px 32px 64px -12px #10182824',
  },
  radius: {
    none: '0rem',
    xxs: '0.125rem',
    xs: '0.25rem',
    sm: '0.375rem',
    md: '0.5rem',
    lg: '0.625rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.25rem',
    '4xl': '1.5rem',
  },
  spacing: {
    none: '0rem',
    xxs: '0.125rem',
    xs: '0.25rem',
    sm: '0.375rem',
    md: '0.5rem',
    lg: '0.75rem',
    xl: '1rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '2rem',
    '5xl': '2.5rem',
    '6xl': '3rem',
    '7xl': '4rem',
    '8xl': '5rem',
    '9xl': '6rem',
    '10xl': '8rem',
    '11xl': '10rem',
  },
  width: {
    lg: 640,
  },
}

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1200,
      xxl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#43C19B',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      contrastText: untitled.colors.gray[600],
    },
    error: {
      main: red.A400,
      contrastText: '#ffffff',
    },
    success: {
      main: green[500],
      contrastText: '#ffffff',
    },
    info: {
      main: blue.A400,
    },
    divider: untitled.colors.divider,
    text: {
      primary: untitled.colors.text.primary,
      secondary: untitled.colors.text.secondary,
    },
    action: {
      disabledBackground: untitled.colors.gray[50],
    },
  },

  typography: {
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
    fontFamily: manrope.fontFamily,
    fontSize: 14,
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontWeight: 500,
    },
    caption: {
      fontWeight: 500,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: untitled.radius.md,
          boxShadow: untitled.shadow.lg,
          border: `1px solid ${untitled.colors.gray[200]}`,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: `${untitled.shadow.lg}!important`,
          borderRadius: `${untitled.radius.md} !important`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none!important',
          borderRadius: untitled.radius.md,
          border: `1px solid ${untitled.colors.divider}`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          border: `1px solid ${untitled.colors.brand[300]}`,
          backgroundColor: `${untitled.colors.brand[50]}`,
          color: untitled.colors.brand[700],
        },
        colorSecondary: {
          border: `1px solid ${untitled.colors.divider}`,
        },
        colorError: {
          border: `1px solid ${untitled.colors.error[100]}`,
          backgroundColor: `${untitled.colors.error[50]}`,
          color: untitled.colors.error[700],
        },
        colorWarning: {
          border: `1px solid ${untitled.colors.warning[300]}`,
          backgroundColor: `${untitled.colors.warning[100]}`,
          color: untitled.colors.warning[700],
        },
        colorSuccess: {
          border: `1px solid ${untitled.colors.success[300]}`,
          backgroundColor: `${untitled.colors.success[100]}`,
          color: untitled.colors.success[700],
        },
        colorInfo: {
          border: `1px solid ${untitled.colors.estaban[400]}`,
          backgroundColor: `${untitled.colors.estaban[50]}`,
          color: untitled.colors.estaban[900],
        },
        sizeSmall: {
          fontSize: 12,
        },
        sizeMedium: {
          fontSize: 14,
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiList-root': {
            paddingTop: untitled.spacing.xs,
            paddingBottom: untitled.spacing.xs,
          },
          '.MuiDivider-root': {
            marginTop: `${untitled.spacing.xs}!important`,
            marginBottom: `${untitled.spacing.xs}!important`,
          },
          '.MuiMenuItem-divider': {
            borderBottomColor: untitled.colors.divider,
          },
        },
        paper: {
          borderRadius: untitled.radius.md,
          boxShadow: untitled.shadow.lg,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: untitled.colors.text.secondary,
          padding: `${untitled.spacing.md} ${untitled.spacing.lg}`,
          '&:hover': {
            color: untitled.colors.text.primary,
          },
          fontSize: 14,
          '.MuiListItemIcon-root': {
            minWidth: 28,
            color: 'inherit',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: untitled.radius.xl,
          },
          '& .MuiDialog-paper': {
            borderRadius: untitled.radius.xl,
          },
          '& .MuiDrawer-paper': {
            borderRadius: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: untitled.colors.divider,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: untitled.radius.md,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: untitled.colors.divider,
            },
            // '&:hover fieldset': {
            //   borderColor: '#3E68A8',
            //   borderWidth: '0.15rem',
            // },
            // '&.Mui-focused fieldset': {
            //   borderColor: '#3E68A8',
            // },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none!important',
          borderRadius: untitled.radius.md,
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-containedSecondary': {
            border: `1px solid ${untitled.colors.gray[300]}`,
            '&:hover': {
              backgroundColor: untitled.colors.gray[50],
              color: untitled.colors.gray[800],
            },
          },
        },
        outlinedInherit: {
          borderColor: untitled.colors.divider,
        },
        outlinedPrimary: {
          '&:disabled': {
            borderColor: untitled.colors.primary,
            color: untitled.colors.primary,
            opacity: 0.5,
          },
        },
        sizeMedium: {
          borderRadius: untitled.radius.md,
          padding: `${untitled.spacing.md} ${untitled.spacing.lg}`,
          height: 40,
        },
        sizeSmall: {
          height: 36,
          padding: `${untitled.spacing.md} ${untitled.spacing.lg}`,
          borderRadius: untitled.radius.md,
          fontSize: '0.875rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: untitled.colors.white,
          boxShadow: '0px 1px 2px 0px #1018280D',
          padding: untitled.spacing.xl,
          position: 'relative',
        },
        outlined: {
          borderWidth: '1px',
          borderColor: untitled.colors.dividerPrimary,
        },
        outlinedError: {
          '& .MuiAlert-icon': {
            color: '#D92D20',
          },
        },
        outlinedSuccess: {
          '& .MuiAlert-icon': {
            color: '#52CDA8',
          },
        },
        outlinedInfo: {
          '& .MuiAlert-icon': {
            color: untitled.colors.gray[500],
          },
        },
        icon: {
          position: 'absolute',
          left: untitled.spacing.xl,
          top: untitled.spacing.xl,
          padding: 0,
        },
        message: {
          padding: `0 34px`,
          color: untitled.colors.text.primary,
          fontWeight: 600,
          fontSize: 14,
        },
        action: {
          padding: 0,
          position: 'absolute',
          right: untitled.spacing['2xl'],
          top: untitled.spacing.xl,
          '& button': {
            margin: '-5px',
          },
          color: untitled.colors.gray[400],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          ...theme.typography.body2,
        }),
      },
    },
  },
  untitled,
})

export const GRADIENT_BACKGROUND = untitled.colors.gray[25]

export const SIDEBAR_TEXT_COLOR = untitled.colors.text.secondary
export const SIDEBAR_TEXT_SELECTED_COLOR = untitled.colors.gray[700]
export const SIDEBAR_BORDER_COLOR = '#e8dbd0'
export const SIDEBAR_BG_COLOR = '#F0E8E1'
export const SIDEBAR_SELECTED_ITEM_BG_COLOR = '#0F2133'
export const SIDEBAR_HIGHTLIGHT_BACKGROUND = untitled.colors.gray[50]
export const SIDEBAR_HIGHTLIGHT_SELECTED_BACKGROUND = untitled.colors.gray[50]
export const SIDEBAR_ICON_COLOR = '#6079AB'

export const STARRED_COLOR = '#EAC44E'
export const DEFAULT_COLOR_CODE = '#fcb900'

export const CHAT_HIGHTLIGHT_BACKGROUND = '#fff6d4'
export const CHAT_HIGHTLIGHT_BACKGROUND_HOVER = '#fcf2ca'
export const MESSAGE_HIGHTLIGHT_BACKGROUND = '#F0E8E1'

export const HEADER_HEIGHT = 55
export const DASHBOARD_MAX_WIDTH = 1400
export default theme

export const mobileThemeColors = {
  background: '#1a1d21',
  primary: '#E18440',
  secondary: '#E18440',
  success: '#5CFF85',
  white: '#ffffff',
  divider: '#2e3033',
  grey0: '#F2F3F3',
  grey1: '#a9a9a9',
  grey2: '#868686',
  grey3: '#5e5e5e',
  grey4: '#23262e',
  grey5: '#23252a',
  greyOutline: '#2d353d',
  black: '#000000',
  error: '#FF6F61',
}

export const TOOL_COLORS = {
  ANNOUNCEMENT: '#FF8A8A',
  ASSET: '#C9007D',
  NOTE: '#8781F9',
  DOC: '#8781F9',
}

export const darkTheme = {
  colors: {
    brand: {
      main: '#E18440',
    },
    text: {
      primary: '#F2F3F3',
    },
  },
}
