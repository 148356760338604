import { Helmet } from 'react-helmet'

interface HeadMetaProps {
  title?: string
}

export function HeadMeta({ title }: HeadMetaProps) {
  return (
    <Helmet>
      <title>
        {title ? `${title} | Umbrella Product` : 'Umbrella Product'}
      </title>
    </Helmet>
  )
}
