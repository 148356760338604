import { getSignedUrlResponse } from './types/upload'
import { getQueryString } from 'utils/queryParams'
import { Client } from './client'
import fetcher from './fetcher'

class UploadService extends Client {
  public async uploadFile(
    query: {
      filename: string
      contentType: string
      isPrivate?: boolean
    },
    file: File | Blob,
    AbortSignal?: AbortSignal,
  ) {
    const { data } = await fetcher<getSignedUrlResponse>(
      `${this.baseUrl}/api/v1/getSignedUrl?${getQueryString(query)}`,
      {
        headers: this.privateHeaders,
      },
    )

    await fetch(data.uploadUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': query.contentType,
        ...(query.isPrivate ? {} : { 'x-goog-acl': 'public-read' }),
      },
      signal: AbortSignal,
    })

    return data
  }
}

const uploadService = new UploadService()

export { uploadService }
