import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { createRootRoute, Outlet } from '@tanstack/react-router'
import { ToastProvider } from 'components/Toast'
import { AuthContextProvider } from 'contexts/auth'
import React from 'react'
import theme from 'utils/theme'

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

export const Route = createRootRoute({
  component: RootComponent,
})

function RootComponent() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider>
        <ToastProvider>
          <Outlet />
          <TanStackRouterDevtools />
        </ToastProvider>
      </AuthContextProvider>
    </ThemeProvider>
  )
}
